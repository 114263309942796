body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  min-height: 70px;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #28a7e9;
}

section:first-of-type {
  margin-top: 70px;
}

section {
  padding-top: 60px;
  padding-bottom: 60px;
}

section a {
  text-decoration: none;
}

.app-icon-container .app-screenshot {
  display: inline-block;
}

.app-icon-container h6 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}

.app-icon {
  margin: 20px auto;
  height: 100px;
  width: 100px;
  border-radius: 20%;
  background-color: #ffffff;
  overflow: hidden;
}

.app-icon img {
  width: 72px;
  height: 72px;
  margin: auto;
}

.app-screenshot img {
  height: 400px;
}